<template>
  <div class="footer-item">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
