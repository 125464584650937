module.exports.links = [{
        title: "AGB.",
        link: "https://www.eprimo.de/agb"
    },
    {
        title: "Impressum",
        link: "https://www.eprimo.de/impressum"
    },
    {
        title: "Datenschutz & Disclaimer",
        link: "https://www.eprimo.de/datenschutz-und-disclaimer"
    },
    {
        title: "Partnerprogramm",
        link: "https://www.eprimo.de/partnerprogramm"
    }
]
