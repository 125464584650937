<template>
  <nav class="nav-wrapper">
    <slot></slot>
  </nav>
</template>

<script>
export default {};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
