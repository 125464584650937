<template>
    <header class="header">
        <v-container>
            <a href="https://www.eprimo.de/">
                <v-img
                        class="logo"
                        width="206"
                        src="@/assets/images/logo.png"
                        alt="logo"
                />
            </a>
        </v-container>
    </header>
</template>

<script>
    export default {
        name: 'Header'
    }
</script>

<style lang="css" scoped>
 .header {
   padding: 20px 0;
 }

 .logo {
   margin-left: -7px;
 }

 @media (max-width: 768px) {

   .header {
     padding: 20px 0 10px;
   }

   .logo {
     width: 100px !important;
   }
 }
</style>