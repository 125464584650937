<template>
  <div class="nav-item">
    <router-link :to="to">{{ checkFlow(title) }}</router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    to: {
      type: Object
    },
    title: {
      type: String
    }
  },

  computed: {
    ...mapState('user', ['currentFlow'])
  },

  methods: {
    checkFlow(title) {
      if (title !== 'Authorization') {
        return title;
      }

      if (this.currentFlow === 'offer_order') {
        return 'Pakete Verbindung';
      }

      if (this.currentFlow === 'mr_dashboard') {
        return 'Dashboard anmelden';
      }
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
