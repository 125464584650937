export default {
  SET_OFFER_DATA: (state, data) => {
    state.offer = data;
  },
  SET_OFFER_TYPE: (state, data) => {
    state.offerType = data;
  },
  SET_METER_READING_DATA: (state, data) => {
    state.meterInfo = data;
  },
  SET_SHORT_METER_READING_DATA: (state, data) => {
    state.shortMeterInfo = data;
  },
  SET_CURRENT: (state, data) => {
    state.currentPackage = data;
  },
  SET_BIGGEST_PACKAGE_ID: (state, data) => {
    state.biggestPackageID = data;
  },
  SET_ERROR: (state, data) => {
    state.currentError = {
      status: data.status,
      message: data.message
    };
  },
  SET_OFFER_INPUT_HISTORY: (state, data) => {
    state.offerInputHistory = {
      email: data.email,
      offer_id: data.offer_id
    };
  },
  SET_ACCESS_TOKEN: (state, token) => {
    state.accessToken = token;
  },
  SET_CURRENT_FLOW: (state, flow) => {
    state.currentFlow = flow;
  },
  SET_DASHBOARD_VISIBILITY: (state, value) => {
    state.dashboardVisibility = value;
  },
  SET_MAINTENANCE_UNTIL: (state, value) => {
    state.maintenanceUntil = value;
  }
};
