import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import RoutesV3 from './v3';
import RoutesV2 from './v2';
import { handleMaintenance } from './maintenanceGuard';

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [...RoutesV3, ...RoutesV2],
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

handleMaintenance(router);

export default router;
