<template>
  <div>
    <v-app v-if="!isNext">
      <Header />
      <v-main>
        <router-view></router-view>
      </v-main>
      <Footer />
    </v-app>

    <app-layout v-else>
      <router-view></router-view>
    </app-layout>
  </div>
</template>

<script>
import Header from './components/Header';
import Footer from './components/Footer';
import AppLayout from './components/next/app-layout.vue';

export default {
  components: {
    Header,
    Footer,
    AppLayout
  },
  computed: {
    isNext() {
      // is ver 3.0
      return this.$route.meta.next;
    }
  }
};
</script>
