import $axios from 'axios';
import router from '../../router';
import { fieldsMR } from '@/placeholder/fieldsValidation';

export default {
  GET_OFFER_DATA: async ({ commit }, data) => {
    let offerNotExpired = true;

    let options = {
      url: `${process.env.VUE_APP_BACKEND_BASE_URL}v3/api/data/${data.offer}`,
      method: 'GET'
    };

    let check = {
      url: `${process.env.VUE_APP_BACKEND_BASE_URL}v3/api/data/output/${data.offer}`,
      method: 'GET'
    };

    await $axios(check)
      .then(res => {
        offerNotExpired = res.data.success;
        commit('SET_OFFER_TYPE', res.data.offer_type);
      })
      .catch(err => {
        offerNotExpired = err.response.data?.success;

        const offerNotFound = [
          'no offer with any `offert_type` exists in the DB',
          'offer is not found by the provided uuid'
        ].includes(err.response.data?.message);

        commit('SET_ERROR', {
          status: false,
          message: offerNotFound
            ? 'Dieser Code ist nicht gültig'
            : 'Dieser Code ist nicht mehr gültig'
        });

        let errorMessage =
          'Bei Fragen wenden Sie sich bitte an den Kundenservice unter';

        if (err.response.data?.message === 'offer exists but is submitted') {
          errorMessage =
            'Sie haben dieses Angebot bereits angenommen. Bei Fragen zu Ihrer Bestellung wenden Sie sich bitte an den Kundenservice unter';
        }

        router.push({
          name: 'ErrorPage',
          params: {
            errorParagraph: {
              message: errorMessage,
              showMail: true
            }
          }
        });
      });

    if (offerNotExpired) {
      return $axios(options)
        .then(res => {
          if (res.data.user) {
            commit('SET_OFFER_DATA', res.data);

            if (data.push) {
              router.push({
                name: 'OfferPackagesPage',
                params: { uuid: data.offer }
              });
            }
          } else {
            commit('SET_METER_READING_LOGIN_ERROR');
          }
        })
        .catch(() => {
          commit('SET_ERROR', {
            status: false,
            message: 'Dieser Code ist nicht gültig'
          });
          router.push({
            name: 'ErrorPage',
            params: {
              errorParagraph: {
                message:
                  'Bei Fragen wenden Sie sich bitte an den Kundenservice unter',
                showMail: true
              }
            }
          });
        });
    }
  },
  GENERATE_PDF: (_, data) => {
    let options = {
      url: process.env.VUE_APP_BACKEND_BASE_URL + 'v3/api/order/pdf',
      method: 'POST',
      responseType: 'blob',
      data: {
        package_id: data.package_id,
        Email: data.Email,
        Email_Confirm: data.Email_Confirm,
        Telefonnummer: data.Telefonnummer,
        Zahlung: data.Zahlung,
        Zahlung_Per: data.Zahlung_Per,
        Rechtliche_Zustimmung: data.Rechtliche_Zustimmung,
        Kontoinhaber: data.Kontoinhaber,
        IBAN: data.IBAN,
        Lastschriftmandat: data.Lastschriftmandat,
        Smart_Meter_Request: data.Smart_Meter_Request,
        faelligkeit_zum: data.Faelligkeit_Zum
      }
    };

    return $axios(options);
  },
  ORDER_SUBMIT: (_, data) => {
    let options = {
      url:
        process.env.VUE_APP_BACKEND_BASE_URL +
        `v3/api/data/submit/${data.offer_id}`,
      method: 'POST',
      query: {
        XDEBUG_SESSION_START: 'netbeans-xdebug'
      },
      data: {
        offer_type: data.offer_type,
        user: {
          Email: data.user.Email,
          Email_Confirm: data.user.Email_Confirm,
          Telefonnummer: data.user.Telefonnummer,
          Zahlung: data.user.Zahlung,
          Zahlung_Per: data.user.Zahlung_Per,
          Rechtliche_Zustimmung: data.user.Rechtliche_Zustimmung,
          Kontoinhaber: data.user.Kontoinhaber,
          IBAN: data.user.IBAN,
          Lastschriftmandat: data.user.Lastschriftmandat,
          Smart_Meter_Request: data.user.Smart_Meter_Request,
          Faelligkeit_Zum: data.user.Faelligkeit_Zum,
          Optin_Pdf: data.user.Optin_Pdf
        },
        package: {
          NameSize: data.package.NameSize
        }
      }
    };

    return $axios(options);
  },
  USER_AUTH: (_, data) => {
    let options = {
      url: process.env.VUE_APP_BACKEND_BASE_URL + `v3/api/user/auth`,
      method: 'POST',
      data: {
        email: data.email,
        password: data.password,
        offer_uuid: data.offerUuid,
        uuid: data.userUuid,
        consent: data.consent
      }
    };

    return $axios(options);
  },
  GET_METER_READING_DATA: async ({ commit, state }, data) => {
    let options = {
      url:
        process.env.VUE_APP_BACKEND_BASE_URL +
        `v3/api/meter_reading/get_id/${data.uuid}`,
      method: 'GET',
      headers: { Authorization: `Bearer ${state.accessToken}` }
    };

    return $axios(options)
      .then(res => {
        commit('SET_METER_READING_DATA', res.data);
        if (data.push) {
          router
            .push({ name: 'InputMeterReading', params: { uuid: data.uuid } })
            .catch();
        }
      })
      .catch(async err => {
        if (err.response.data?.message === 'Authentication error') {
          await router
            .push({ name: 'AuthUser', params: { uuid: data.uuid } })
            .catch();
        } else {
          router.push({ name: 'CodeProvideReading' });
          fieldsMR.tokenMR.valid = false;
          fieldsMR.tokenMR.error =
            'Ungultiger E-Mail Code. Bitte verwenden Sie den richtigen Code\n' +
            'aus der letzten E-Mail, die wir Ihnen dazu gesendet haben. ';
        }
      });
  },
  GET_DATA_BEFORE_AUTH: ({ commit, state }, data) => {
    if (state.accessToken && state.shortMeterInfo?.uuid === data.uuid) {
      return true;
    }

    let options = {
      url:
        process.env.VUE_APP_BACKEND_BASE_URL +
        `v3/api/meter_reading/get_short/${data.uuid}`,
      method: 'GET'
    };

    return $axios(options)
      .then(res => {
        commit('SET_SHORT_METER_READING_DATA', {
          ...res.data,
          can_submit: true,
          uuid: data.uuid
        });
      })
      .catch(err => {
        commit('SET_SHORT_METER_READING_DATA', {
          can_submit: false,
          uuid: data.uuid
        });

        if (err.response?.status === 401) {
          router.push({ name: 'AuthUser', params: { uuid: data.uuid } });
        } else {
          router.push({ name: 'CodeProvideReading' });
          fieldsMR.tokenMR.valid = false;
          fieldsMR.tokenMR.error =
            'Ungultiger E-Mail Code. Bitte verwenden Sie den richtigen Code\n' +
            'aus der letzten E-Mail, die wir Ihnen dazu gesendet haben. ';
        }
      });
  },
  METER_READING_SUBMIT: (_, data) => {
    let options = {
      url:
        process.env.VUE_APP_BACKEND_BASE_URL +
        `v3/api/meter_reading/create/${data.uuid}`,
      method: 'POST',
      data: {
        reading_at: data.reading_at,
        meter_value: data.meter_value
      }
    };

    return $axios(options);
  },

  SMART_METER_ORDER_SUBMIT: (_, data) => {
    let options = {
      url:
        process.env.VUE_APP_BACKEND_BASE_URL +
        `v3/api/order/smart_meter/${data.uuid}`,
      method: 'POST'
    };

    return $axios(options);
  },

  GET_MONTHLY_CONSUMPTION: async ({ state }, data) => {
    const options = {
      url:
        process.env.VUE_APP_BACKEND_BASE_URL +
        `v3/api/meter_reading/get_monthly_by_id/${data.uuid}?month=${data.month}`,
      method: 'GET',
      headers: { Authorization: `Bearer ${state.accessToken}` }
    };

    return $axios(options)
      .then(res => res.data.consumption)
      .catch(() => 0);
  }
};
