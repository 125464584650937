<template>
  <footer class="footer">
    <div class="footer-wrapper">
      <slot></slot>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
