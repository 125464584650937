import VueGtm from 'vue-gtm';
import Vue from 'vue';
import App from './App.vue';
import VueCookies from 'vue-cookies';
import vuetify from './plugins/vuetify';
import router from './router';
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/styles/general.scss';
import VueApexCharts from 'vue-apexcharts'
import VueScrollTo from 'vue-scrollto'
import VueEllipseProgress from 'vue-ellipse-progress';
import RoundSlider from 'vue-round-slider'
import Axios from "axios"
import store from "./store/index"

Vue.prototype.$axios = Axios;

Vue.use(RoundSlider);
Vue.use(VueEllipseProgress);

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(BootstrapVue)

Vue.use(VueGtm, {
    id: 'GTM-TN6QQQ6',
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router
});


Vue.use(VueScrollTo)

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.mixin({
    methods: {
        sendRequest(url, options) {
            if (process.env.VUE_APP_BACKEND_AUTHORIZATION) {
                if (options.headers)
                options.headers = {
                    'Authorization': 'Basic ' + btoa(`${process.env.EPRIMO_DEV_LOGIN}:${process.env.EPRIMO_DEV_PASS}`) //'strompakete' + ":" + '111111'
                }
            }

            return fetch(url, options)
        }
    }
})

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
