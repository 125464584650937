module.exports.fields = {
	email: {
		value: '',
		error: '',
		valid: true,
		visible: true
	},
	email_confirm: {
		value: '',
		error: '',
		valid: true,
		visible: true
	},
	telefonnummer: {
		value: '',
		error: '',
		valid: true,
		visible: true
	},
	payment: 'Einmalzahlung',
	paymentBy: false,
	iban: {
		value: '',
		error: '',
		valid: true,
		visible: true
	},
	kontoinhaber: {
		value: '',
		error: '',
		valid: true,
		visible: true
	},
	lastschriftmandat: {
		value: false,
		error: '',
		valid: true,
		visible: true,
		type: 'checkbox'
	},
	dueTo: 1,
	rechtliche_zustimmung: {
		value: false,
		error: '',
		valid: true,
		visible: true,
		type: 'checkbox'
	},
	optin_pdf: {
		value: false,
		error: '',
		valid: true,
		visible: true,
		type: 'checkbox'
	}
}

module.exports.validateFields = [
	'email',
	'email_confirm',
	'kontoinhaber',
	'iban',
	'lastschriftmandat',
	'rechtliche_zustimmung',
	'optin_pdf'
]

module.exports.fieldsMR = {
	tokenMR: {
		value: '',
		error: 'Ungultiger E-Mail Code. Bitte verwenden Sie den richtigen Code \n aus der letzten E-Mail, die wir Ihnen dazu gesendet haben. ',
		valid: true,
		visible: true
	},
	meterValue: {
		value: '',
		error: 'Der Zählerstand ist ein Pflichtfeld',
		valid: true,
		visible: true
	}
}

module.exports.fieldsOrder = {
	token: {
		value: '',
		error: 'Ungultiger E-Mail Code. Bitte verwenden Sie den richtigen Code \n aus der letzten E-Mail, die wir Ihnen dazu gesendet haben. ',
		valid: true,
		visible: true
	}
}

module.exports.fieldsAuth = {
	email: {
		value: '',
		error: '',
		valid: true,
		visible: true
	},
	password: {
		value: '',
		error: '',
		valid: true,
		visible: true
	},
}

module.exports.validateAuthFields = [
	'email',
	'password',
]