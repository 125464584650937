import store from '@/store';
import { routeVersion } from './types';

export default [
  {
    path: `/${routeVersion.V3}`,
    name: 'AuthCode',
    component: async () => import('@/pages/V3/CodeProvide.vue'),
    props: true,
    meta: {
      showLoginButton: true,
      title: 'eprimo Strompakete - AuthCode',
      gtm: 'eprimo Strompakete - AuthCode',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'login'
        },
        {
          title: 'Pakete',
          name: 'AuthCode'
        }
      ]
    }
  },
  {
    path: `/aktion`,
    name: 'Action',
    redirect: `/${routeVersion.V3}`
  },
  {
    path: `/${routeVersion.V3}/code-benutzt`,
    name: 'ErrorPage',
    props: true,
    component: async () => import('@/pages/V3/ErrorPage.vue'),
    meta: {
      title: 'eprimo Strompakete - Error',
      gtm: 'eprimo Strompakete - Error',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'AuthCode'
        },
        {
          title: 'Code nicht gültig',
          name: 'ErrorPage'
        }
      ]
    }
  },
  {
    path: `/${routeVersion.V3}/maintenance`,
    name: 'Maintenance',
    component: async () => import('@/pages/V3/Maintenance.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Maintenance',
      gtm: 'eprimo Strompakete - Maintenance',
      next: true
    }
  },
  {
    path: `/${routeVersion.V3}/zaehlerstand`,
    name: 'CodeProvideReading',
    component: async () => import('@/pages/V3/MeterReading/CodeProvide.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Meter Reading',
      gtm: 'eprimo Strompakete - Meter Reading',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'AuthCode'
        },
        {
          title: 'Zählerstandserfassung',
          name: 'CodeProvideReading'
        }
      ]
    }
  },
  {
    path: `/${routeVersion.V3}/zaehlerstand/danke`,
    name: 'ThankYouMeterReading',
    component: async () => import('@/pages/V3/MeterReading/ThankYou.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Meter Reading',
      gtm: 'eprimo Strompakete - Meter Reading',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'CodeProvideReading'
        },
        {
          title: 'Vielen Dank',
          name: 'ThankYouMeterReading'
        }
      ]
    }
  },
  {
    path: `/${routeVersion.V3}/zaehlerstand/error`,
    name: 'ErrorMeterReading',
    component: async () => import('@/pages/V3/MeterReading/Error.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Meter Reading',
      gtm: 'eprimo Strompakete - Meter Reading',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'CodeProvideReading'
        },
        {
          title: 'Zählerstandserfassung',
          name: 'ErrorMeterReading'
        }
      ]
    }
  },
  {
    path: `/${routeVersion.V3}/zaehlerstand/:uuid`,
    name: 'InputMeterReading',
    component: async () => import('@/pages/V3/MeterReading/InputReading.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Meter Reading',
      gtm: 'eprimo Strompakete - Meter Reading',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'CodeProvideReading'
        },
        {
          title: 'Zählerstand',
          name: 'InputMeterReading'
        }
      ]
    },
    beforeEnter: async (to, from, next) => {
      const res = await store
        .dispatch('user/GET_DATA_BEFORE_AUTH', {
          uuid: to.params.uuid
        })
        .then(() => true)
        .catch(() => false);

      if (!res) {
        return `/${routeVersion.V3}/${to.params.uuid}/auth`;
      }

      next();
    }
  },
  {
    path: `/${routeVersion.V3}/login`,
    name: 'LoginPage',
    component: async () => import('@/pages/V3/AuthUser.vue'),
    props: true,
    alias: '/login',
    meta: {
      title: 'eprimo Strompakete - Auth',
      gtm: 'eprimo Strompakete - Auth',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'AuthCode'
        },
        {
          title: 'Authorization',
          name: 'AuthUser'
        }
      ]
    }
  },
  {
    path: `/${routeVersion.V3}/:uuid`,
    name: 'OfferPackagesPage',
    component: async () => import('@/pages/V3/OfferPackagesPage.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Bestellung',
      gtm: 'eprimo Strompakete - Bestellung',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'AuthCode'
        },
        {
          title: 'Pakete',
          name: 'OfferPackagesPage'
        }
      ]
    }
  },
  {
    path: `/${routeVersion.V3}/:uuid/auth`,
    name: 'AuthUser',
    component: async () => import('@/pages/V3/AuthUser.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Auth',
      gtm: 'eprimo Strompakete - Auth',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'AuthCode'
        },
        {
          title: 'Authorization',
          name: 'AuthUser'
        }
      ]
    }
  },
  {
    path: `/${routeVersion.V3}/:uuid/package`,
    name: 'orderPackage',
    component: async () => import('@/pages/V3/OrderPackagePage.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Bestellung',
      gtm: 'eprimo Strompakete - Bestellung',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'AuthCode'
        },
        {
          title: 'Pakete',
          name: 'OfferPackagesPage'
        },
        {
          title: 'Bestellung',
          name: 'orderPackage'
        }
      ]
    }
  },
  {
    path: `/${routeVersion.V3}/:uuid/danke`,
    name: 'Danke',
    component: async () => import('@/pages/V3/ThankYouPage.vue'),
    meta: {
      title: 'eprimo Strompakete - Danke',
      gtm: 'eprimo Strompakete - Danke',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'AuthCode'
        },
        {
          title: 'Pakete',
          name: 'OfferPackagesPage'
        },
        {
          title: 'Bestellung',
          name: 'OfferPackagesPage'
        },
        {
          title: 'Vielen Dank',
          name: 'Danke'
        }
      ]
    }
  },
  {
    path: `/${routeVersion.V3}/:uuid/danke/smartmeter`,
    name: 'ThankYouSmartMeterPage',
    component: async () => import('@/pages/V3/ThankYouSmartMeterPage.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - ThankYouSmartMeterPage',
      gtm: 'eprimo Strompakete - ThankYouSmartMeterPage',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'AuthCode'
        },
        {
          title: 'SmartMeter-Bestsellung',
          name: 'ThankYouSmartMeterPage'
        }
      ]
    }
  }
];
