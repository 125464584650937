<template>
  <div id="next-layout">
    <logo />
    <login-button v-if="loginButtonVisibility" />

    <div class="next-app-container">
      <nav-base>
        <nav-item
          v-for="(crumb, id) in crumbs"
          :key="id"
          :to="{ name: crumb.name }"
          :title="crumb.title"
        />
      </nav-base>

      <slot></slot>

      <footer-base>
        <div class="footer-block"></div>

        <div class="footer-block">
          <footer-item v-for="(item, idx) in links" :key="idx">
            <a :href="item.link">{{ item.title }}</a>
          </footer-item>
        </div>
      </footer-base>
    </div>
  </div>
</template>

<script>
import { links } from '../../placeholder/footer';
import Logo from '@/components/next/logo.vue';
import LoginButton from '@/components/next/login-button.vue';
import NavBase from '@/components/next/nav/nav-base.vue';
import NavItem from '@/components/next/nav/nav-item.vue';
import FooterBase from '@/components/next/footer/footer-base.vue';
import FooterItem from '@/components/next/footer/footer-item.vue';

export default {
  components: { Logo, NavBase, NavItem, FooterBase, FooterItem, LoginButton },

  data: () => ({
    crumbs: {},
    links
  }),

  computed: {
    loginButtonVisibility() {
      return !!this.$route?.meta?.showLoginButton;
    }
  },

  mounted() {
    this.crumbs = this.$route?.meta?.breadcrumbs;
  },

  watch: {
    $route() {
      this.crumbs = this.$route?.meta?.breadcrumbs;
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
