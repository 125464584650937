import $axios from 'axios';
import store from '../store';

export function handleMaintenance(router) {
  router.beforeEach(async (to, from, next) => {
    let options = {
      url: process.env.VUE_APP_BACKEND_BASE_URL + `v3/api/public/maintenance`,
      method: 'GET'
    };

    store.commit('user/SET_MAINTENANCE_UNTIL', null);

    try {
      const res = await $axios(options);
      const until = res?.data?.until;

      if (until) {
        store.commit('user/SET_MAINTENANCE_UNTIL', until);

        if (to.path !== '/v3/maintenance') {
          return next('/v3/maintenance');
        }
      } else {
        if (to.path === '/v3/maintenance') {
          return next('/');
        }
      }
    } catch (err) {
      console.error(err);
    }

    return next();
  });
}
