<template>
  <router-link to="/v3/login" id="login-button">
    <Icon icon="ic:outline-person-outline" style="font-size:1.5rem;" />
    Kundenlogin
  </router-link>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default {
  components: { Icon }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
