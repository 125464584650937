import { routeVersion } from './types';

export default [
  {
    path: `/faq`,
    name: 'Faq',
    component: async () => import('@/pages/V2/Faq.vue'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Faq',
      gtm: 'eprimo Strompakete - Faq',
      next: true,
      breadcrumbs: [
        {
          title: 'Startseite',
          name: 'AuthCode'
        },
        {
          title: 'Faq',
          name: 'Faq'
        }
      ]
    }
  },
  {
    path: `/`,
    component: async () => import('@/pages/V2/LoginPage'),
    name: 'login',
    meta: { title: 'eprimo Strompakete - Aktionscode' },
    // beforeEnter: (to, from, next) => {
    //     if (Vue.$cookies.get('uuid')) {
    //         next({
    //             name: 'packagesPage',
    //             params: {
    //                 uuid: Vue.$cookies.get('uuid')
    //             }
    //         });
    //     } else {
    //         next();
    //     }
    // },
    props: true,
    redirect: `/${routeVersion.V3}`
  },
  {
    path: `/zaehlerstand`,
    name: 'meter_reading_login',
    component: async () => import('@/pages/V2/MeterReading/Login'),
    props: true,
    redirect: `/${routeVersion.V3}/zaehlerstand`
  },
  {
    path: `/zaehlerstand/:token`,
    name: 'input',
    component: async () => import('@/pages/V2/MeterReading/Input'),
    props: true,
    redirect: to => `/${routeVersion.V3}/zaehlerstand/${to.params.token}`
  },
  {
    path: `/smartmeter`,
    name: 'meter_ordering_login',
    component: async () => import('@/pages/V2/MeterOrdering/Login'),
    props: true
  },
  {
    path: `/smartmeter/danke`,
    name: 'meter_ordering_thanks',
    component: async () => import('@/pages/V2/MeterOrdering/ThankYouOrder'),
    meta: {
      title: 'eprimo Strompakete - Smartmeter Vielen Dank',
      gtm: 'eprimo Strompakete - Smartmeter Vielen Dank'
    }
  },
  {
    path: `/smartmeter/:token`,
    name: 'meter_ordering_form',
    component: async () => import('@/pages/V2/MeterOrdering/FormPage')
  },
  {
    path: `/smartmeter/code-benutzt`,
    name: 'meter_ordering_error',
    component: async () => import('@/pages/V2/MeterOrdering/ErrorOrder'),
    meta: {
      title: 'eprimo Strompakete - Aktionscode nicht gültig',
      gtm: 'eprimo Strompakete - Aktionscode nicht gültig'
    }
  },
  {
    path: `/danke`,
    name: 'thankYou',
    component: async () => import('@/pages/V2/ThankYou'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Vielen Dank',
      gtm: 'eprimo Strompakete - Vielen Dank'
    }
    // beforeEnter(to, from, next) {
    //     if (!Vue.$cookies.get('uuid')) {
    //         next({ name: 'login'});
    //     } else {
    //         next();
    //     }
    // }
  },
  {
    path: `/danke-new-offer`,
    name: 'thankYouOffer',
    component: async () => import('@/pages/V2/ThankYouNewOffer'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Vielen Dank',
      gtm: 'eprimo Strompakete - Vielen Dank'
    }
    // beforeEnter(to, from, next) {
    //     if (!Vue.$cookies.get('uuid')) {
    //         next({ name: 'login'});
    //     } else {
    //         next();
    //     }
    // }
  },
  {
    path: `/danke-smartmeter`,
    name: 'thankYouSmartmeter',
    component: async () => import('@/pages/V2/ThankYouStrompakete'),
    meta: {
      title: 'eprimo Strompakete - Smartmeter Vielen Dank',
      gtm: 'eprimo Strompakete - Smartmeter Vielen Dank'
    }
    // beforeEnter(to, from, next) {
    //     if (from.name !== 'thankYou') {
    //         next({ name: 'login'});
    //     } else {
    //         next();
    //     }
    // }
  },
  {
    path: `/danke-strompakete`,
    name: 'thankYouPackage',
    component: async () => import('@/pages/V2/ThankYouPackage'),
    meta: {
      title: 'eprimo Strompakete - Smartmeter Vielen Dank',
      gtm: 'eprimo Strompakete - Smartmeter Vielen Dank'
    }
  },
  {
    path: `/code-benutzt`,
    name: 'error',
    component: async () => import('@/pages/V2/Error'),
    meta: {
      title: 'eprimo Strompakete - Aktionscode nicht gültig',
      gtm: 'eprimo Strompakete - Aktionscode nicht gültig'
    }
  },
  {
    path: `/code-abgelaufen`,
    name: 'expiredCode',
    component: async () => import('@/pages/V2/ExpiredCode'),
    meta: {
      title: 'eprimo Strompakete - Angebot nicht mehr gültig',
      gtm: 'eprimo Strompakete - Angebot nicht mehr gültig'
    }
  },
  {
    path: `/:uuid`,
    name: 'packagesPage',
    component: async () => import('@/pages/V2/PackagesPage'),
    meta: {
      title: 'eprimo Strompakete - Angebot',
      gtm: 'eprimo Strompakete - Angebot'
    },
    redirect: to => `/${routeVersion.V3}/${to.params.uuid}`
  },
  {
    path: `/:uuid`,
    name: 'formPage',
    component: async () => import('@/pages/V2/FormPage'),
    props: true,
    meta: {
      title: 'eprimo Strompakete - Bestellung',
      gtm: 'eprimo Strompakete - Bestellung'
    },
    redirect: to => `/${routeVersion.V3}/${to.params.uuid}/package`
  }
];
