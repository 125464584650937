export default () => ({
  currentPackage: {},
  offerType: '',
  biggestPackageID: 0,
  user: {},
  offer: {},
  meterInfo: {},
  shortMeterInfo: {},
  accessToken: '',
  currentFlow: 'mr_dashboard',
  currentError: {
    status: false,
    message: 'ERR_NO_CODE'
  },
  offerInputHistory: {
    email: '',
    offer_id: ''
  },
  dashboardVisibility: false,
  maintenanceUntil: null
});
