<template>
    <footer class="footer">
        <v-container>
            <v-row>
                <v-col class="col" sm="3" cols="12">
<!--                  <button class="more-details" @click.stop="dialog = true">*Mehr Infos</button>-->
                  <v-dialog
                      v-model="dialog"
                      scrollable
                  >
                    <v-card>
                      <v-card-title>
                        <div class="h1">*Mehr Infos</div>
                        <button class="epicon epicon-close" @click="dialog = false"></button>
                      </v-card-title>
                      <v-card-text>
                      <h2>So rechnen wir:</h2>
                      <p>Unser Tarifrechner zeigt Ihnen unseren für Ihren Verbrauch und Ihre Postleitzahl passenden Tarif.</p>
                      <p>Die Bruttopreise sind kaufmännisch gerundet.</p>
                      <h2>Unsere Boni für Neukunden:</h2>
                      <p>Bei Online-Vertragsabschluss oder telefonisch via
                        Service-Hotline 0800&nbsp;/&nbsp;60&nbsp;60&nbsp;110 (kostenlos aus dem deutschen Festnetz, Mobilfunkkosten können abweichen), können Sie je nach gewähltem Tarif
                        folgende Boni zu den genannten Konditionen erhalten:</p>
                      <h2>PrimaKlima-Bonus</h2>
                      <p>Den „PrimaKlima-Bonus“ erhalten Sie nach 365 Tagen Belieferung durch eprimo. Voraussetzung ist,
                        dass Sie ab dem ersten Belieferungstag ununterbrochen 365 Tage Energie von eprimo bezogen haben. Ein bereits
                        ausgezahlter Bonus kann bei Nichterfüllung der Voraussetzungen ggf. zurückgefordert werden (vgl. Ziffer 5.4 der
                        AGB).</p>
                      <h2>Treuebonus</h2>
                      <p>
                        Den „Treuebonus“ erhalten Sie, soweit sich Ihr Vertrag nach der ersten Vertragslaufzeit gemäß Ziffer 15.2 der
                        AGB verlängert und Sie nach Ablauf der Erstvertragslaufzeit weiter ununterbrochen von eprimo mit Energie
                        beliefert werden. Dieser wird nach 720 Tagen durchgängiger Belieferung durch eprimo auf Ihr Konto überwiesen.
                        Sollten die Voraussetzungen aus einem von Ihnen zu vertretenden Grund nicht erfüllt werden, entfällt der
                        Anspruch auf den Bonus. Der Anspruch auf den Treuebonus bleibt bestehen, sofern Sie den Vertrag vorzeitig in
                        Ausübung eines Ihnen zustehenden Rechts (z.B. Sonderkündigungsrecht) beenden.
                      </p>

                      <h2>Arbeitspreisrabatt</h2>
                      <p>Sie erhalten zeitlich begrenzt einen Rabatt auf den Arbeitspreis in Ihrem Tarif. Im Anschluss gilt der für den
                        Tarif angegebene Arbeitspreis ohne Rabattierung. Nähere Informationen zur Dauer und zur Höhe des
                        Arbeitspreisrabattes, finden Sie in den Tarifdetails.
                      </p>
                      <h2>Preisgarantie</h2>
                      <p>Bis zum genannten Datum bzw. innerhalb der genannten Monate ab Belieferungsbeginn wird eprimo keine
                        Preisanpassung
                        vornehmen. Ausgenommen hiervon sind Anpassungen des von eprimo nicht beeinflussbaren Teil des Strompreises,
                        soweit
                        er Steuern, Abgaben und Umlagen erfasst sowie bei etwaiger Einführung neuer Steuern, Abgaben oder sonstigen
                        staatlich veranlassten, die Beschaffung, Erzeugung, Netznutzung oder den Verbrauch elektrischer Energie
                        betreffende
                        Belastungen (vgl. Ziffer 6.1 der AGB).</p>
                      <h2>Preisgarantie für Treueprodukte</h2>
                      <p>Bis zum genannten Datum bzw. innerhalb der genannten Monate ab Belieferungsbeginn wird eprimo keine
                        Preisanpassung
                        vornehmen.
                        Ausgenommen hiervon sind Anpassungen aufgrund von Änderungen der Umsatz- und Energiesteuer sowie bei etwaiger
                        Einführung neuer Steuern, Abgaben oder sonstigen staatlich veranlassten, die Beschaffung, Erzeugung, Netznutzung
                        oder den Verbrauch von Gas betreffende Belastungen (vgl. Ziffer 6.2 und 6.3 der AGB).
                      </p>
                      <h2>Vertragslaufzeit</h2>
                      <p>Sofern Ihr gewählter Tarif keine feste Vertragslaufzeit vorsieht, ist dieser mit einer Frist von einem Monat zum
                        Ende
                        eines Kalendermonats gemäß Ziffer 16.1 und 16.5 der AGB Strom / Gas kündbar. Ansonsten hat der Vertrag eine
                        Vertragslaufzeit bis zum genannten Datum bzw. der genannten Monate ab Belieferungsbeginn. Die Vertragslaufzeit
                        verlängert sich automatisch um jeweils 12 Monate, wenn der Vertrag nicht fristgemäß zum Ende der
                        Vertragslaufzeit
                        gemäß Ziffer 16.2 und 16.5 der AGB gekündigt wird.</p>
                      <h2>Heizungspumpe tauschen</h2>
                      <p>
                        Beim Austausch von zwei Heizkreispumpen und einer Zirkulationspumpe und in Abhängigkeit von Alter, Modell und
                        Verbrauch Ihrer derzeitigen Pumpen. <a href="https://perto.de/wp-content/uploads/2018/04/SampleOffer.pdf" target="_blank">
                        Hier</a> geht es zur Beispielrechnung unseres Partners perto.
                      </p>
                      <h2>Plug &amp; Play Solarmodule</h2>
                      <p>
                        Ersparnis bei einer Laufzeit von 20 Jahren, Strombezugspreis: 28ct/kWh, Selbstverbrauch: 100%,
                        Strompreisteuerungsrate: 5,00% / Jahr, Kapitalzins: 2%, Berechnungsgrundlage: PV*SOL PRO 5.5<br>
                        Standort: Ulm, Ausrichtung: Süd, keine Verschattung, Berechnungsgrundlage: PV*SOL PRO 5.5<br>
                        270Wp PV-Leistung, Erzeugung 280kWh pro Jahr, 100% Eigenverbrauch. Ersparnis von bis zu 78 EUR / Jahr.<br>
                        Bei Verwendung des Produktes sind die jeweils geltenden nationalen Rechtsvorschriften, die Anschlussbedingungen
                        des
                        Netzbetreibers und die daraus evtl. folgenden Beschränkungen zu beachten! Der Anschluss ist mit dem
                        Netzbetreiber
                        abzustimmen und darf in Deutschland nur von einem Fachbetrieb für Elektroinstallationen vorgenommen
                        werden.<br>
                        Mieter müssen ggf. die Installation mit dem Vermieter abklären.
                      </p>
                      <h2>eprimo.Klar</h2>
                      <p>
                        <u>Kostenübernahme Zählereinbau:</u> eprimo übernimmt Kosten des Zählereinbaus bis zu einer Höhe von 150 €. Über
                        diesen Betrag hinausgehende Kosten sind von Ihnen zu tragen. Solche Mehrkosten können sich je nach Ihrer
                        Wohnsituation, etwa abhängig von der Position des Zählerschrankes, ergeben und lassen sich im Vorfeld nicht
                        berechnen. Sollten die 150 € nicht ausreichen, um die Einbaukosten abzudecken, werden Sie durch den Techniker
                        vorab informiert, sobald der Techniker die Situation vor Ort begutachtet hat.
                      </p>
                      <p>
                        <u>Zählerbetrieb:</u> Bei dem Produkt eprimo.Klar schließen Sie neben dem Strombelieferungsvertrag mit uns auch
                        einen gesonderten Messstellenvertrag mit unserem Partner Discovergy ab.
                        eprimo übernimmt die laufenden Kosten für den Messstellenbetrieb des Smart Meters während Sie eprimo.Klar Kunde
                        sind. Die Kündigung des Strombelieferungsvertrages lässt den Messstellenvertrag unberührt.
                        Dieser muss ebenfalls gekündigt werden, wenn der Messstellenbetrieb durch Discovergy nicht fortgesetzt werden
                        soll. Die Kündigung muss gegenüber Discovergy erfolgen,
                        entweder durch Sie oder durch uns, wenn Sie uns entsprechend informieren. Setzen Sie den Messstellenvertrag mit
                        Discovergy nach der Laufzeit des Strombelieferungsvertrages fort,
                        wird Ihnen Discovergy die für den Messstellenbetrieb anfallenden Kosten als Ihr Messstellenbetreiber unmittelbar
                        in Rechnung stellen.

                      </p>
                      <p>
                        <u>Monatliche Rechnung:</u>
                        Ab dem Einbau des Smart Meters erstellt eprimo kostenfrei eine monatliche Rechnung auf Basis Ihres tatsächlichen
                        Verbrauches. Bis zum Einbau des Smart Meters zahlen Sie wie gewohnt einen regelmäßigen Abschlag.
                      </p>

                      <h2>eprimo ist ausgezeichnet und zertifiziert: </h2>

                        <v-row>
                          <v-col sm="6">
                            <v-row class="m-0">
                              <v-col sm="4">
                                <p>
                                  <a href="https://www.eprimo.de/ueber-eprimo/unternehmen/auszeichnungen-bewertungen/">
                                    <img src="@/assets/images/PreisChampions_2020.svg" alt="PreisChampions_2020">
                                  </a>
                                </p>
                              </v-col>
                              <v-col sm="8">
                                <p><span class="text-bold-medium">Preis-Champions</span></p>
                                <p>"DIE WELT" kürt eprimo als Preis-Champions in der Kundenbegeisterung.</p>
                                <p>
                                  <small>Quelle: DIE WELT 04/2020</small>
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col sm="6">
                            <v-row class="m-0">
                              <v-col sm="4">
                                <p>
                                  <a href="https://www.eprimo.de/ueber-eprimo/unternehmen/auszeichnungen-bewertungen/">
                                    <img src="@/assets/images/TOP10-Stromversorger-07-2020-Deutschlands.svg" alt="TOP10-Stromversorger-07-2020-Deutschlands">
                                  </a>
                                </p>
                              </v-col>
                              <v-col sm="8">
                                <p><span class="text-bold-medium">Deutschlands Top 10 Stromversorger</span></p>
                                <p>eprimo wird von Focus Money als einer der "Top 10 Stromversorger in Deutschland" ausgezeichnet.</p>
                                <p><small>Quelle: Focus Money 07/2020</small></p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col sm="6">
                            <v-row class="m-0">
                              <v-col sm="4">
                                <p>
                                  <a href="https://www.eprimo.de/ueber-eprimo/unternehmen/auszeichnungen-bewertungen/">
                                    <img src="@/assets/images/do-sq-eprimo.png" alt="do-sq-eprimo">
                                  </a>
                                </p>
                              </v-col>
                              <v-col sm="8">
                                <p><span class="text-bold-medium">Servicequalität "Sehr gut"</span></p>
                                <p>Die Servicequalität von eprimo wurde vom TÜV Süd mit „sehr gut“ zertifiziert.</p>
                                <p>
                                  <small>Quelle: <a href="https://bit.ly/2PvlAKE" target="_blank">https://bit.ly/2PvlAKE</a></small><br>
                                  <small>Bericht: <a href="https://content.eprimo.de/fileadmin/pdf/TUV_Pruefbericht_eprimo_2019.pdf" target="_blank">Prüfbericht</a></small>
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col sm="6">
                            <v-row class="m-0">
                              <v-col sm="4">
                                <p>
                                  <a href="https://www.eprimo.de/ueber-eprimo/unternehmen/auszeichnungen-bewertungen/">
                                    <img src="@/assets/images/TUEV_Nord_Gepruefter-Oekostrom.svg" alt="TUEV_Nord_Gepruefter-Oekostrom">
                                  </a>
                                </p>
                              </v-col>
                              <v-col sm="8">
                                <p><span class="text-bold-medium">Geprüfter Ökostrom</span></p>
                                <p>TÜV NORD zertifiziert den Strom von eprimo als "Geprüften Ökostrom"</p>
                                <p>
                                  <small>Zertifikat: die Zertifizierung Ökostrom</small><br>
                                  <small>Download: VdTÜV <a href="https://bit.ly/2KS3apm" target="_blank">Merkblatt
                                    1304</a><br> (Ausgabe 10.2014) (kostenpflichtig)</small>
                                </p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col sm="6">
                            <v-row class="m-0">
                              <v-col sm="4">
                                <p>
                                  <a href="https://www.eprimo.de/ueber-eprimo/unternehmen/auszeichnungen-bewertungen/">
                                    <img src="@/assets/images/HB_YouGov_PreisLeistungSieger_2020_eprimo.svg" alt="">
                                  </a>
                                </p>
                              </v-col>
                              <v-col sm="8">
                                <p><span class="text-bold-medium">Exzellentes Preisleistungsverhältnis</span></p>
                                <p>Auch 2020 wird eprimo mit einem "Exzellenten Preisleistungsverhältnis" ausgezeichnet.</p>
                                <p><small>Quelle: Handelsblatt 19.02.2020</small></p>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col class="col" sm="9" cols="12">
                    <nav>
                        <ul class="nav">
                            <li class="nav-item"><a href="https://www.eprimo.de/ueber-eprimo/agb">AGB</a></li>
                            <li class="nav-item"><a href="https://www.eprimo.de/ueber-eprimo/impressum">Impressum</a></li>
                            <li class="nav-item"><a href="https://www.eprimo.de/ueber-eprimo/datenschutzerklaerung">Datenschutz &amp; Disclaimer</a></li>
                            <li class="nav-item"><a href="https://www.eprimo.de/ueber-eprimo/partnerprogramm">Partnerprogramm</a></li>
                            <!--end-nav-menu__list-->
                        </ul>
                    </nav>
                </v-col>
            </v-row>
        </v-container>
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
        data() {
          return {
            dialog: false
          }
        }
    }
</script>

<style lang="scss" scoped>
    .footer {
      padding: 30px 0;
    }

    .nav {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        list-style: none;
        justify-content: flex-end;

        &-item {
            font-size: 12px;
            line-height: 21px;
            position: relative;

            &:after {
                content: '|';
                position: relative;
                bottom: .0625rem;
                padding: 0 1.25rem 0 .9375rem;
                color: #7b7e88;
            }

            &:last-of-type:after {
                content: none;
            }
        }

        a {
            color: $grey;
        }
    }

    .more-details {
        font-size: 12px;
        color: $grey;
    }

    .col {
      padding: 0 12px;
    }

    @media (max-width: 768px) {
      .col {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center;

        &:first-of-type {
          margin-bottom: 15px;
        }
      }

      .nav {
        justify-content: center;
      }
    }
</style>