import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

import { state, mutations, getters, actions } from './user';

export default new Vuex.Store({
  modules: {
    user: {
      namespaced: true,
      state,
      getters,
      actions,
      mutations
    }
  },
  plugins: [
    createPersistedState({
      storage: sessionStorage
    })
  ]
});
