<template>
  <header class="header">
    <div class="header-wrapper">
      <div class="header-logo-wrapper">
        <div class="wave">
          <div class="logo">
            <router-link :to="{ name: 'AuthCode' }"> </router-link>
          </div>
        </div>
      </div>

      <div class="header-nav-wrapper"></div>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
